const OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION = [
  "원산지 표기 미부착 및 오기 등 이슈가 발생하는 경우 추가 발생 비용은 실비청구되고 수입자가 부담합니다.",
  "세관 무작위 검사 적발 시, 관세사 수수료 50,000원(VAT 별도)가 추가 발생됩니다. (*통관을 쉽다 파트너에게 의뢰 시)",
  "선적된 화물의 실제 컨테이너 종류/수량 및 중량에 따라 견적이 변동될 수 있습니다.",
  "위험물, 2단적재 불가 등 특수한 경우 견적이 상이할 수 있고, 선적이 불가할 수 있습니다.",
  "물류비 대금정산은 국내 항구 도착 후, 실제 선적된 컨테이너 종류/수량에 따라 청구됩니다.",
  "터미널 FREETIME 및 STORAGE는 선사별 기본으로 제공되며, 별도 추가를 원하시는 경우 담당자와 협의 부탁드립니다.",
];

const OCEAN_FCL_CAUTION_LIST_FOR_EXPORTATION = [
  "실제 청구 시 환율은 On Board Date 기준, 하나은행에서 최초 고시한 송금할 때의 환율로 적용됩니다.",
  "20ft 컨테이너가 해당 선사 기준에 중량 초과시, OWS(Over Weight Surcharge)가 발생됩니다.",
  "위험물 및 기타 특수한 화물의 경우 선적이 불가하거나 견적이 변동될 수 있습니다.",
];

const OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION = [
  "원산지 표기 미부착 및 오기 등 이슈가 발생하는 경우 추가 발생 비용은 실비청구되고 수입자가 부담합니다.",
  "세관 무작위 검사 적발 시, 관세사 수수료 50,000원(VAT 별도)가 추가 발생됩니다. (*통관을 쉽다 파트너에게 의뢰 시)",
  "선적된 화물의 실제 컨테이너 종류/수량 및 중량에 따라 견적이 변동될 수 있습니다.",
  "위험물, 2단적재 불가 등 특수한 경우 견적이 상이할 수 있고, 선적이 불가할 수 있습니다.",
  "다수 판매자(수출자)로부터 수입하는 경우, 지정된 장소에서 화물을 모두 수취 후 선적됨이 원칙입니다.",
  "물류비 대금정산은 국내 항구 도착 후, 실제 선적된 부피/중량에 따라 청구됩니다.",
  "터미널 FREETIME 및 STORAGE는 선사별 기본으로 제공되며, 별도 추가를 원하시는 경우 담당자와 협의 부탁드립니다.",
  "국내 창고보관료는 반입일 포함 7일 무료(주말 포함)입니다. 이후 일 단위로 R.TON당 6,000원씩 보관료가 발생합니다.\n예시) 2R.TON 화물을 10일 보관 가정 시, 무료기간 7일을 제외한 3일의 초과보관료가 청구\n(3일 * 2 R.TON * 6,000원 = 36,000원)",
];

const OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION = [
  "실제 청구 시 환율은 On Board Date 기준, 하나은행에서 최초 고시한 송금할 때의 환율로 적용됩니다.",
  "견적단위가 R.TON인 항목은, 거래명세서 발행시 최종적으로 선적된 R.TON 값으로 적용되어 청구됩니다.",
  "위험물, 2단적재 불가, 장축화물 등 특수한 경우 선적이 불가하거나 견적이 변동될 수 있습니다.",
  "수출운송 시 1R.TON 미만의 화물은 최소 운임 기준에 따라 1R.TON 요율이 적용됩니다.",
];

const AIR_CAUTION_LIST_FOR_IMPORTATION = [
  "원산지 표기 미부착 및 오기 등 이슈가 발생하는 경우 추가 발생 비용은 실비청구되고 수입자가 부담합니다.",
  "세관 무작위 검사 적발 시, 관세사 수수료 50,000원(VAT 별도)가 추가 발생됩니다. (*통관을 쉽다 파트너에게 의뢰 시)",
  "선적된 화물의 실제 부피/중량에 따라 견적이 변동될 수 있습니다.",
  "위험물, 2단적재 불가 등 특수한 경우 견적이 상이할 수 있고, 선적이 불가할 수 있습니다.",
  "물류비 대금정산은 국내 공항 도착 후, 실제 선적된 부피/중량에 따라 청구됩니다.",
  "국내 창고보관료는 반입되는 보세창고 종가/종량 기준에 따라 실비 청구됩니다.",
];

const AIR_CAUTION_LIST_FOR_EXPORTATION = [
  "실제 청구 시 환율은 출항일 기준, 하나은행에서 최초 고시한 송금할 때의 환율로 적용됩니다.",
  "위험물, 2단적재 불가, 장축화물 등 특수한 경우 선적이 불가하거나 견적이 변동될 수 있습니다.",
  "C.W는 항공사 보세창고 반입 시 실측되어 변경될 수 있고, 이에 따라 견적이 변동될 수 있습니다.",
];

const OCEAN_TICKET_CAUTION_LIST = [
  "원산지 표기 미부착 및 오기 등 이슈가 발생하는 경우 추가 발생 비용은 실비청구되고 수입자가 부담합니다.",
  "세관 무작위 검사 적발 시, 관세사 수수료 50,000원(VAT 별도)가 추가 발생됩니다. (*통관을 쉽다 파트너에게 의뢰 시)",
  "선적된 화물의 실제 부피/중량에 따라 견적이 변동될 수 있습니다.",
  "위험물, 2단적재 불가 등 특수한 경우 견적이 상이할 수 있고, 선적이 불가할 수 있습니다.",
  "다수 판매자(수출자)로부터 수입하는 경우, 지정된 장소에서 화물을 모두 수취 후 선적됨이 원칙입니다.",
  "물류비 대금정산은 국내 항구 도착 후, 실제 선적된 부피/중량에 따라 청구됩니다.",
  "국내 창고보관료는 반입일 포함 7일 무료(주말 포함)입니다. 이후 일 단위로 R.TON당 6,000원씩 보관료가 발생합니다.\n예시) 2R.TON 화물을 10일 보관 가정 시, 무료기간 7일을 제외한 3일의 초과보관료가 청구\n(3일 * 2 R.TON * 6,000원 = 36,000원)",
];

const CONSOL_CAUTION_LIST = [
  "원산지 표기 미부착 및 오기 등 이슈가 발생하는 경우 추가 발생 비용은 실비청구되고 수입자가 부담합니다.",
  "세관 무작위 검사 적발 시, 관세사 수수료 50,000원(VAT 별도)가 추가 발생됩니다. (*통관을 쉽다 파트너에게 의뢰 시)",
  "선적된 화물의 실제 부피/중량에 따라 견적이 변동될 수 있습니다.",
  "사전 동의없이 [마이페이지]에 기재된 창고로 화물을 입고시킬 경우 거절할 수 있고 쉽다는 이에 책임지지 않습니다.",
  "위험물, 2단적재 불가 등 특수한 경우 견적이 상이할 수 있고, 선적이 불가할 수 있습니다.",
  "다수 판매자(수출자)로부터 수입하는 경우, 지정된 장소에서 화물을 모두 수취 후 선적됨이 원칙입니다.",
  "소상품 신고로 수출통관이 진행됩니다. 수출자 명의로 통관을 희망하실 경우 통관비가 청구됩니다.",
  "소상품 신고로 수출통관이 진행되는 경우, 한국 수입통관 후 환불 등 사유로 인한 SHIPBACK은 불가하고 이슈 발생에 대한 책임을 지지 않습니다.",
  "물류비 대금정산은 국내 항구 도착 후, 실제 선적된 부피/중량에 따라 청구됩니다.",
  "국내 창고보관료는 7일 무료입니다. 초과 시 보세창고 종가/종량 기준에 따라 실비 청구됩니다.",
];

export {
  AIR_CAUTION_LIST_FOR_EXPORTATION,
  AIR_CAUTION_LIST_FOR_IMPORTATION,
  CONSOL_CAUTION_LIST,
  OCEAN_FCL_CAUTION_LIST_FOR_EXPORTATION,
  OCEAN_FCL_CAUTION_LIST_FOR_IMPORTATION,
  OCEAN_LCL_CAUTION_LIST_FOR_EXPORTATION,
  OCEAN_LCL_CAUTION_LIST_FOR_IMPORTATION,
  OCEAN_TICKET_CAUTION_LIST,
};
