import styled from "styled-components";

import { TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const dimmedLayer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1000; /* 지도가 아닌 다른 요소들 위에 오도록 설정 */
`;

const message = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;

  > .text {
    ${setFontStyle("SubHead3")};
    color: ${TEXT_COLOR.white_1};
  }
`;

export default {
  container,
  dimmedLayer,
  message,
};
